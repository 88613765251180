import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import CookiesPopUp from 'components/CookiesPopUp';
import Seo from 'components/Seo';
import SiteNavigation from 'components/SiteNavigation';
import UserAlert from 'components/UserAlert';
import Footer from 'components/Footer';

const FabButton = loadable(() => import('components/FabButton'));

const MainLayout = ({ location, children, globalBoost, handleGlobalBoost }) => {
  const [userInteracted, setUserInteracted] = useState(false);
  const acounts = ['/cf/cuenta', '/cf/cuenta/lista-de-espera', '/tarifas'];
  const homes = ['/', '/v2'];
  const homesNew = ['/v2'];
  const isHome = homes.some(home => location.pathname === home);
  const isHomeNew = homesNew.some(home => location.pathname === home);
  const isAccount = acounts.some(account => location.pathname === account);

  useEffect(() => {
    const handleInteraction = () => {
      if (!location.search.includes('CI=true')) {
        setUserInteracted(true);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('click', handleInteraction);
      window.addEventListener('scroll', handleInteraction);
    }

    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, [location.search]);

  return (
    <>
      <header className={isHome ? 'is-home' : ''}>
        {isHome && <div id="site-alerts" className={`${isHome ? 'hide-desktop' : ''}`}></div>}
        <SiteNavigation newHome={isHomeNew} pageName={location.pathname} />
      </header>
      <main>{React.cloneElement(children, { globalBoost })}</main>
      <FabButton {...{ globalBoost, handleGlobalBoost, location }} />
      {userInteracted && <CookiesPopUp />}
      <Footer newHome={isHomeNew} accountPage={isAccount} home={isHome} />
      <UserAlert location={location} />
    </>
  );
};

export default MainLayout;

export const Head = () => <Seo />;
