exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-academia-casos-de-exito-jsx": () => import("./../../../src/pages/academia/casos-de-exito.jsx" /* webpackChunkName: "component---src-pages-academia-casos-de-exito-jsx" */),
  "component---src-pages-academia-contentful-category-page-category-slug-jsx": () => import("./../../../src/pages/academia/{contentfulCategoryPage.category__slug}.jsx" /* webpackChunkName: "component---src-pages-academia-contentful-category-page-category-slug-jsx" */),
  "component---src-pages-academia-index-jsx": () => import("./../../../src/pages/academia/index.jsx" /* webpackChunkName: "component---src-pages-academia-index-jsx" */),
  "component---src-pages-access-jsx": () => import("./../../../src/pages/access.jsx" /* webpackChunkName: "component---src-pages-access-jsx" */),
  "component---src-pages-auth-error-jsx": () => import("./../../../src/pages/auth-error.jsx" /* webpackChunkName: "component---src-pages-auth-error-jsx" */),
  "component---src-pages-bold-smart-pro-manual-jsx": () => import("./../../../src/pages/bold-smart-pro/manual.jsx" /* webpackChunkName: "component---src-pages-bold-smart-pro-manual-jsx" */),
  "component---src-pages-boldplus-manual-jsx": () => import("./../../../src/pages/boldplus/manual.jsx" /* webpackChunkName: "component---src-pages-boldplus-manual-jsx" */),
  "component---src-pages-cf-cuenta-index-jsx": () => import("./../../../src/pages/cf/cuenta/index.jsx" /* webpackChunkName: "component---src-pages-cf-cuenta-index-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-datafonos-jsx": () => import("./../../../src/pages/datafonos.jsx" /* webpackChunkName: "component---src-pages-datafonos-jsx" */),
  "component---src-pages-datafonos-neo-jsx": () => import("./../../../src/pages/datafonos/neo.jsx" /* webpackChunkName: "component---src-pages-datafonos-neo-jsx" */),
  "component---src-pages-datafonos-plus-jsx": () => import("./../../../src/pages/datafonos/plus.jsx" /* webpackChunkName: "component---src-pages-datafonos-plus-jsx" */),
  "component---src-pages-datafonos-smart-jsx": () => import("./../../../src/pages/datafonos/smart.jsx" /* webpackChunkName: "component---src-pages-datafonos-smart-jsx" */),
  "component---src-pages-datafonos-smart-pro-jsx": () => import("./../../../src/pages/datafonos/smart-pro.jsx" /* webpackChunkName: "component---src-pages-datafonos-smart-pro-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-abonos-inmediatos-cuenta-bold-jsx": () => import("./../../../src/pages/legal/abonos-inmediatos-cuenta-bold.jsx" /* webpackChunkName: "component---src-pages-legal-abonos-inmediatos-cuenta-bold-jsx" */),
  "component---src-pages-legal-aviso-privacidad-jsx": () => import("./../../../src/pages/legal/aviso-privacidad.jsx" /* webpackChunkName: "component---src-pages-legal-aviso-privacidad-jsx" */),
  "component---src-pages-legal-bold-pos-jsx": () => import("./../../../src/pages/legal/bold-pos.jsx" /* webpackChunkName: "component---src-pages-legal-bold-pos-jsx" */),
  "component---src-pages-legal-comercios-actividades-prohibidas-jsx": () => import("./../../../src/pages/legal/comercios-actividades-prohibidas.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-actividades-prohibidas-jsx" */),
  "component---src-pages-legal-comercios-devolucion-jsx": () => import("./../../../src/pages/legal/comercios-devolucion.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-devolucion-jsx" */),
  "component---src-pages-legal-comercios-generalidades-jsx": () => import("./../../../src/pages/legal/comercios-generalidades.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-generalidades-jsx" */),
  "component---src-pages-legal-comercios-jsx": () => import("./../../../src/pages/legal/comercios.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-jsx" */),
  "component---src-pages-legal-comercios-manual-ventas-no-presentes-jsx": () => import("./../../../src/pages/legal/comercios-manual-ventas-no-presentes.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-manual-ventas-no-presentes-jsx" */),
  "component---src-pages-legal-comercios-manual-ventas-presentes-jsx": () => import("./../../../src/pages/legal/comercios-manual-ventas-presentes.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-manual-ventas-presentes-jsx" */),
  "component---src-pages-legal-comercios-pagos-jsx": () => import("./../../../src/pages/legal/comercios-pagos.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-pagos-jsx" */),
  "component---src-pages-legal-comercios-referidos-jsx": () => import("./../../../src/pages/legal/comercios-referidos.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-referidos-jsx" */),
  "component---src-pages-legal-comercios-sagrilaft-jsx": () => import("./../../../src/pages/legal/comercios-sagrilaft.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-sagrilaft-jsx" */),
  "component---src-pages-legal-comercios-tarjeta-codensa-jsx": () => import("./../../../src/pages/legal/comercios-tarjeta-codensa.jsx" /* webpackChunkName: "component---src-pages-legal-comercios-tarjeta-codensa-jsx" */),
  "component---src-pages-legal-compradores-terminos-y-condiciones-jsx": () => import("./../../../src/pages/legal/compradores-terminos-y-condiciones.jsx" /* webpackChunkName: "component---src-pages-legal-compradores-terminos-y-condiciones-jsx" */),
  "component---src-pages-legal-contrato-apertura-de-credito-cupo-jsx": () => import("./../../../src/pages/legal/contrato-apertura-de-credito-cupo.jsx" /* webpackChunkName: "component---src-pages-legal-contrato-apertura-de-credito-cupo-jsx" */),
  "component---src-pages-legal-contrato-credito-capital-jsx": () => import("./../../../src/pages/legal/contrato-credito-capital.jsx" /* webpackChunkName: "component---src-pages-legal-contrato-credito-capital-jsx" */),
  "component---src-pages-legal-contrato-de-credito-jsx": () => import("./../../../src/pages/legal/contrato-de-credito.jsx" /* webpackChunkName: "component---src-pages-legal-contrato-de-credito-jsx" */),
  "component---src-pages-legal-garantia-anti-fraude-jsx": () => import("./../../../src/pages/legal/garantia-anti-fraude.jsx" /* webpackChunkName: "component---src-pages-legal-garantia-anti-fraude-jsx" */),
  "component---src-pages-legal-index-jsx": () => import("./../../../src/pages/legal/index.jsx" /* webpackChunkName: "component---src-pages-legal-index-jsx" */),
  "component---src-pages-legal-informacion-cupo-jsx": () => import("./../../../src/pages/legal/informacion-cupo.jsx" /* webpackChunkName: "component---src-pages-legal-informacion-cupo-jsx" */),
  "component---src-pages-legal-partners-bold-jsx": () => import("./../../../src/pages/legal/partners-bold.jsx" /* webpackChunkName: "component---src-pages-legal-partners-bold-jsx" */),
  "component---src-pages-legal-politica-de-cookies-jsx": () => import("./../../../src/pages/legal/politica-de-cookies.jsx" /* webpackChunkName: "component---src-pages-legal-politica-de-cookies-jsx" */),
  "component---src-pages-legal-privacidad-datos-personales-jsx": () => import("./../../../src/pages/legal/privacidad-datos-personales.jsx" /* webpackChunkName: "component---src-pages-legal-privacidad-datos-personales-jsx" */),
  "component---src-pages-legal-terminos-y-condiciones-cupo-bold-jsx": () => import("./../../../src/pages/legal/terminos-y-condiciones-cupo-bold.jsx" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-cupo-bold-jsx" */),
  "component---src-pages-legal-terminos-y-condiciones-pagos-y-recargas-jsx": () => import("./../../../src/pages/legal/terminos-y-condiciones-pagos-y-recargas.jsx" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-pagos-y-recargas-jsx" */),
  "component---src-pages-nairo-jsx": () => import("./../../../src/pages/nairo.jsx" /* webpackChunkName: "component---src-pages-nairo-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-pagos-en-linea-link-de-pago-jsx": () => import("./../../../src/pages/pagos-en-linea/link-de-pago.jsx" /* webpackChunkName: "component---src-pages-pagos-en-linea-link-de-pago-jsx" */),
  "component---src-pages-pagos-en-linea-pasarela-de-pagos-jsx": () => import("./../../../src/pages/pagos-en-linea/pasarela-de-pagos.jsx" /* webpackChunkName: "component---src-pages-pagos-en-linea-pasarela-de-pagos-jsx" */),
  "component---src-pages-promo-jsx": () => import("./../../../src/pages/promo.jsx" /* webpackChunkName: "component---src-pages-promo-jsx" */),
  "component---src-pages-referidos-jsx": () => import("./../../../src/pages/referidos.jsx" /* webpackChunkName: "component---src-pages-referidos-jsx" */),
  "component---src-pages-seguridad-negocios-jsx": () => import("./../../../src/pages/seguridad-negocios.jsx" /* webpackChunkName: "component---src-pages-seguridad-negocios-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-soporte-compradores-jsx": () => import("./../../../src/pages/soporte-compradores.jsx" /* webpackChunkName: "component---src-pages-soporte-compradores-jsx" */),
  "component---src-pages-tarifas-grandes-negocios-jsx": () => import("./../../../src/pages/tarifas/grandes-negocios.jsx" /* webpackChunkName: "component---src-pages-tarifas-grandes-negocios-jsx" */),
  "component---src-pages-tarifas-index-jsx": () => import("./../../../src/pages/tarifas/index.jsx" /* webpackChunkName: "component---src-pages-tarifas-index-jsx" */),
  "component---src-pages-tarifas-simulador-jsx": () => import("./../../../src/pages/tarifas/simulador.jsx" /* webpackChunkName: "component---src-pages-tarifas-simulador-jsx" */),
  "component---src-pages-tarifas-tarifas-antiguas-jsx": () => import("./../../../src/pages/tarifas/tarifas-antiguas.jsx" /* webpackChunkName: "component---src-pages-tarifas-tarifas-antiguas-jsx" */),
  "component---src-pages-v-2-jsx": () => import("./../../../src/pages/v2.jsx" /* webpackChunkName: "component---src-pages-v-2-jsx" */),
  "component---src-pages-whatsapp-jsx": () => import("./../../../src/pages/whatsapp.jsx" /* webpackChunkName: "component---src-pages-whatsapp-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-success-case-js": () => import("./../../../src/templates/success-case.js" /* webpackChunkName: "component---src-templates-success-case-js" */)
}

