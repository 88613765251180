import React from 'react';
import SegmentButton from 'components/website/SegmentButton';

const Dropdown = ({ submenu, featured, dropdown }) => {
  return (
    <div className={`menu-drawer ${dropdown ? 'open' : ''}`}>
      <ol
        className={`${
          submenu.length > 1 ? 'site-navigation__sub-menu-flex site-navigation__sub-menu' : 'site-navigation__sub-menu'
        }`}
      >
        {submenu.map(({ title, pages }, index) => (
          <li key={`column-${index + 1}`} className="site-navigation__column">
            {title && <h3>{title}</h3>}
            <ol className="site-navigation__sub-sub-menu">
              {pages.map((item, id) => (
                <li className="site-navigation__menu-item" key={`main-navigation-sub-item-${Number(id)}`}>
                  <SegmentButton
                    event="navbar"
                    url={item?.slug ? `/${item?.slug}` : item.link}
                    isLink
                    isBlank={item.blank}
                    obj={{
                      button_name: item.title,
                      redirect_to: item?.slug ? `/${item.slug}` : item.link,
                    }}
                  >
                    {item.icon}
                    <span>
                      <span className="up-text">{item?.upText}</span>
                      {item.title}
                    </span>
                    {item.badge && <span className="badge">{item.badge}</span>}
                  </SegmentButton>

                  {item?.customContent}
                </li>
              ))}
            </ol>
          </li>
        ))}

        <div className="site-navigation__featured">
          {featured?.map(({ image, title, upText, link }, index) => (
            <div key={`featured-${Number(index)}`} className="featured-item">
              {Array.isArray(image) ? (
                <ul>
                  {image.map((img, imgIndex) => (
                    <li key={`featured-res-${imgIndex + 1}`}>{img}</li>
                  ))}
                </ul>
              ) : (
                image
              )}
              <h3>{upText}</h3>
              <h2>{title}</h2>
              <a href={link} target="_blank" rel="noopener noreferrer">
                Conoce más aquí
              </a>
            </div>
          ))}
        </div>
      </ol>
    </div>
  );
};

export default Dropdown;
