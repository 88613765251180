const COMPANY_INFO = Object.freeze({
  NIT: '901.281.572-4',
  ADDRESS: 'Carrera 11 # 93A-08, Local 102, Bogotá, D.C., Colombia',
  CONTACT: {
    PHONE: '+576015143530',
    WHATSAPP: { SUPPORT: '573166240694', SALES: '573185865168', CONTACT_CF: '573124643883' },
    MAIL: { SALES: 'ventas@bold.co', SUPPORT: 'soporte@bold.co' },
  },
  SOCIAL: {
    FACEBOOK_URL: 'https://www.facebook.com/somosbold.co',
    INSTAGRAM_URL: 'https://www.instagram.com/somosbold.co/',
    YOUTUBE_URL: 'https://www.youtube.com/channel/UC53BgFWNZGvNs6Pt0YzYT9A',
    LINKEDIN_URL: 'https://www.linkedin.com/company/boldteam/mycompany/',
  },
  STORE: 'https://tienda.bold.co/',
  JOBS: 'https://boldteam.recruitee.com/',
  ONBOARDING: 'https://comercios.bold.co/banking-onboarding',
  DATAPHONES_LINKS: {
    NEO: 'https://tienda.bold.co/products/neo',
    PLUS: 'https://tienda.bold.co/products/datafono-plus',
    SMART: 'https://tienda.bold.co/products/smart',
    SMART_PRO: 'https://tienda.bold.co/products/smart-pro',
  },
  DATAPHONES_PRICES: {
    NEO: '$59.000',
    PLUS: '$149.000',
    SMART: '$299.000',
    SMART_PRO: '$499.000',
  },
});

export default COMPANY_INFO;
