/**
 *  @function
 *  Mask a string given a pattern formed by '#'
 *  i.e 1234, ##-## -> 12-34
 *  @param {string} value - string value
 *  @param {string} pattern - string pattern
 *  @returns {string} masked string that match the pattern
 */
function maskString(value, pattern) {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, () => {
    i += 1;
    return v[i - 1];
  });
}

export default maskString;
