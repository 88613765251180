import React from 'react';
import { BoldButton } from '@bold/web-components-react';

import ICf from 'assets/boldcf.inline.svg';
import ISas from 'assets/bold-icon.inline.svg';

const BlankLayout = ({ parameter, children }) => {
  const MERCHANT_URL = process.env.GATSBY_MERCHANTS_URL;
  const SUPER_PANEL_URL = process.env.GATSBY_SUPER_PANEL_URL;

  return (
    <>
      {parameter === 'super-panel' ? (
        <header className="header-blank-layout header-blank-layout-colors-sp">
          <div className="logo-blank-layout">
            <ICf />
          </div>
          <div className="section-flex">
            <a href={MERCHANT_URL} rel="noopener noreferrer" className="color-white">
              Pagos
            </a>
            <BoldButton size="default" outlined type="button">
              Cuenta Bold
            </BoldButton>
          </div>
        </header>
      ) : (
        <header className="header-blank-layout header-blank-layout-colors-mp">
          <div className="logo-blank-layout">
            <ISas className="color-white i-sas" />
          </div>
          <div className="section-flex ">
            <BoldButton size="default" outlined type="button">
              Pagos
            </BoldButton>
            <a href={SUPER_PANEL_URL} rel="noopener noreferrer" className="color-white">
              Cuenta Bold
            </a>
          </div>
        </header>
      )}

      {children}
    </>
  );
};

export default BlankLayout;
