import { graphql, useStaticQuery } from 'gatsby';

const useCategoryList = () => {
  const {
    contentfulCategoryNavigation: { categoryList },
  } = useStaticQuery(graphql`
    {
      contentfulCategoryNavigation {
        categoryList {
          name
          id
          slug
        }
      }
    }
  `);

  return categoryList;
};

export default useCategoryList;
