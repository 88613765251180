import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BoldDialog } from '@bold/web-components-react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import isWithinInterval from 'date-fns/isWithinInterval';
import add from 'date-fns/add';

const UserAlert = ({ location }) => {
  const {
    allContentfulPopUpInformativo: { nodes: alerts },
  } = useStaticQuery(graphql`
    {
      allContentfulPopUpInformativo {
        nodes {
          contentful_id
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, width: 1200)
          }
          ctaLabel
          ctaLink
          startDate
          endDate
          where
        }
      }
    }
  `);

  const [userAlerts, setUserAlerts] = useState([]);

  const handleDismiss = id => {
    setUserAlerts(existingAlerts => {
      const foundIdex = existingAlerts.findIndex(i => i.contentful_id === id);
      if (foundIdex > -1) {
        existingAlerts[foundIdex].show = false;
      }
      return existingAlerts;
    });
  };

  useEffect(() => {
    setUserAlerts(alerts.map(alert => ({ ...alert, show: false })));
  }, []);

  useEffect(() => {
    const shouldShow = alert => {
      const { startDate, endDate, where } = alert;

      return (
        isWithinInterval(new Date(), {
          start: new Date(startDate),
          end: add(new Date(endDate), { hours: 23, minutes: 59, seconds: 59 }),
        }) && (where !== null ? where.some(i => i === location.href) : true)
      );
    };

    setUserAlerts(existingAlerts => existingAlerts.map(i => ({ ...i, show: shouldShow(i) })));
  }, [location.href]);

  return (
    <>
      {userAlerts.map(alert => (
        <BoldDialog
          show={alert.show}
          type="modal"
          size="large"
          key={alert.contentful_id}
          onDialogDismiss={() => handleDismiss(alert.contentful_id)}
        >
          <h2 slot="title">{alert.title}</h2>
          <GatsbyImage image={getImage(alert.image)} alt={alert.title} style={{ width: '100%' }} />
          <bold-button slot="buttons" onClick={() => window.open(alert.ctaLink, '_blank', 'noopener noreferrer')}>
            {alert.ctaLabel}
          </bold-button>
        </BoldDialog>
      ))}
    </>
  );
};

export default UserAlert;
