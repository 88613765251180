/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import { BoldButton, BoldLink } from '@bold/web-components-react';
import cookieStorage from 'utils/storage';
import { segmentTrack } from 'vendors/segment';

import Close from 'assets/icons/ic_close.inline.svg';

import './styles.scss';

const CookiesPopUp = () => {
  const [activeCookie, setActiveCookie] = useState(false);

  useEffect(() => {
    if (!cookieStorage.getCookie('webcf-cookies')) setActiveCookie(value => !value);
  }, []);

  const handledCookie = () => {
    setActiveCookie(!activeCookie);
    cookieStorage.setCookie('webcf-cookies', 1, 6);

    segmentTrack('Cookies Accepted');
  };

  const handleNavigation = () => {
    navigate('/legal/politica-de-cookies#accordion-content-3');
    segmentTrack('Cookies Configuration');
  };

  const closeCookieModal = () => {
    setActiveCookie(!activeCookie);
    cookieStorage.setCookieSession('webcf-cookies', 1);
    segmentTrack('Cookies Closed');
  };

  return (
    <section id="cookies" className={`${activeCookie ? 'active-cookie' : ''}`}>
      <div className="section-container bg-background shadow-black-4">
        <span id="close" className="color-blue-100" onClick={closeCookieModal} aria-label="Cerrar modal de cookies">
          <Close alt="Bold" />
        </span>
        <div className="wrapper">
          <h3 className="color-blue-100 text-700 mb-12">Cookies</h3>
          <p className="text-400">
            En Bold usamos Cookies para entender cómo usas nuestra página web, proporcionar contenido de terceros y
            apoyar nuestras iniciativas de marketing. Puedes mejorar tu experiencia aceptando la{' '}
            <Link to="/legal/politica-de-cookies#accordion-content-3">Política de cookies.</Link>{' '}
          </p>
          <div className="buttons">
            <BoldLink
              className="button-link-wrapper"
              size="full"
              color="accent"
              type="button"
              outlined
              onClick={handleNavigation}
            >
              Configurar
            </BoldLink>
            <div className="button-width">
              <BoldButton
                className="button-cookie-wrapper"
                size="full"
                color="accent"
                type="button"
                onClick={handledCookie}
              >
                Aceptar y continuar
              </BoldButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CookiesPopUp;
