const cookieStorage = {
  getCookie: cname => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [coname, value]) => ({ ...acc, [coname.trim()]: value }), {});
    return cookies[cname];
  },
  setCookie: (cname, cvalue, exdays, isDay) => {
    const m = new Date();
    if (isDay) {
      m.setTime(m.getTime() + exdays * 24 * 60 * 60 * 1000);
    } else {
      m.setMonth(m.getMonth() + exdays);
    }

    const expires = `expires=${m.toUTCString()}`;
    document.cookie = `${cname} = ${cvalue}; ${expires}; path=/`;
  },

  setCookieSession: (cname, cvalue) => {
    document.cookie = `${cname} = ${cvalue}; path=/`;
  },
};

export default cookieStorage;
