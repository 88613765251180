/* eslint-disable no-restricted-syntax */
const getSearchParams = base => {
  let url = '';
  if (!isValidUrl(base)) {
    url = new URL('https://bold.co/');
    loopParams(url);
    return base + url.search;
  } else {
    url = new URL(base);
    loopParams(url);
    return url;
  }
};

const loopParams = url => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      for (const param of params) {
        url.searchParams.set(param[0], param[1]);
      }
    }
  }
};

const loopParamsSegment = () => {
  const isBrowser = typeof window !== 'undefined';
  const p = {};
  if (isBrowser) {
    const params = new URLSearchParams(window.location.search);
    if (params) {
      params.forEach((value, key) => {
        p[key] = value;
      });
    }
  }

  return p;
};

const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export { getSearchParams, loopParams, isValidUrl, loopParamsSegment };
