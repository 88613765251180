/**
 *  @function
 *  returns true if the user device is listed as mobile
 *  @returns {boolean}
 */
function isMobile() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some(toMatchItem => {
    return (navigator.userAgent || navigator.vendor || window.opera).match(toMatchItem);
  });
}

export default isMobile;
