const BREAKPOINTS = Object.freeze({
  SX: '(min-width: 320px)',
  SM: '(min-width: 540px)',
  MD: '(min-width: 758px)',
  LG: '(min-width: 900px)',
  XL: '(min-width: 1280px)',
  XX: '(min-width: 1440px)',
});

export default BREAKPOINTS;
