import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import SegmentButton from 'components/website/SegmentButton';
import ChevronIcon from 'assets/chevron-icon.inline.svg';
import Dropdown from 'components/SiteNavigation/Dropdown';
import useMediaQuery from 'hooks/useMediaQuery';
import BREAKPOINTS from 'constants/breakpoints';

const MenuItems = ({ item }) => {
  const [dropdown, setDropdown] = useState(false);
  const matches = useMediaQuery(BREAKPOINTS.XL);
  const menuRef = useRef(null);

  useEffect(() => {
    const eventHandler = event => {
      if (dropdown && menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', eventHandler);
      document.addEventListener('touchstart', eventHandler);
    }

    return () => {
      document.removeEventListener('mousedown', eventHandler);
      document.removeEventListener('touchstart', eventHandler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    if (matches) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (matches) {
      setDropdown(false);
    }
  };

  const closeDropdown = () => {
    if (dropdown) {
      setDropdown(false);
    }
  };

  const handleDropdownclick = (link = '') => {
    setDropdown(prev => !prev);
    if (matches && link !== '') {
      navigate(link);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <li
      className="site-navigation__menu-item"
      ref={menuRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {item.childPages ? (
        <>
          <div className="site-navigation__menu-item-title">
            {item?.icon && item.icon}
            <SegmentButton
              event="navbar"
              obj={{
                button_name: item.title,
                redirect_to: 'open menu',
              }}
            >
              <button
                ref={menuRef}
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown}
                onClick={() => handleDropdownclick(item.link)}
              >
                <span>{item.title}</span>
                <ChevronIcon />
              </button>
            </SegmentButton>
          </div>
          <Dropdown submenu={item.childPages} featured={item.featuredArticle} dropdown={dropdown} />
        </>
      ) : (
        <div className="site-navigation__menu-item-title">
          {item?.icon && item.icon}
          <SegmentButton
            event="navbar"
            url={item?.slug ? `/${item.slug}` : item.link}
            isLink
            obj={{
              button_name: item.title,
              redirect_to: item?.slug ? `/${item.slug}` : item.link,
            }}
          >
            {item.title}
          </SegmentButton>
        </div>
      )}
    </li>
  );
};

export default MenuItems;
