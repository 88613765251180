import React, { useState } from 'react';
import MainLayout from './MainLayout';
import AcademyLayout from './AcademyLayout';
import BlankLayout from './BlankLayout';

import './styles.scss';

const Layout = ({ children, location, pageContext }) => {
  const [globalBoost, setGlobalBoost] = useState('');
  const { search } = location;
  const params = new URLSearchParams(search);
  const parameter = params.get('from');

  const handleGlobalBoost = chatobj => {
    setGlobalBoost(chatobj);
  };

  if (pageContext.layout === 'special') {
    return <main>{children}</main>;
  } else if (pageContext.layout === 'blank') {
    return <BlankLayout parameter={parameter}>{children}</BlankLayout>;
  } else if (pageContext.layout === 'academy') {
    return <AcademyLayout {...{ location, globalBoost, handleGlobalBoost }}>{children}</AcademyLayout>;
  }

  return <MainLayout {...{ location, globalBoost, handleGlobalBoost }}>{children}</MainLayout>;
};

export default Layout;
