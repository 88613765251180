/* eslint-disable no-nested-ternary */
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { getSearchParams, loopParamsSegment } from 'utils/search-params-functions';
import { segmentTrack } from 'vendors/segment';
import goToSupportURL from 'utils/whatsappFunction';
import isMobile from 'utils/isMobile';

const SegmentButton = ({ children, event, url, isBlank, isLink, isViral, hideClick, obj, wpText }) => {
  const [eventState, setEventState] = useState('');

  useEffect(() => {
    const eventsObj = {
      purchase: 'Purchase Clicked',
      contact: 'Contact Click',
      contactsales: 'Contact Sales',
      contactsupport: 'Contact Support',
      button: 'Button Clicked',
      register: 'Registration Clicked',
      waiting: 'Waiting List Registration Clicked',
      academy: 'Academy Opened',
      login: 'Login Clicked',
      documentation: 'View Documentation',
      navbar: 'Nav Bar Button Clicked',
    };

    return setEventState(eventsObj[event]);
  }, [event]);

  const blank = isBlank ? 'a' : Link;
  const WrapperComponent = isLink ? blank : 'span';

  const handleSalesSupport = (e, text) => {
    e.preventDefault();

    const whatsAppOptions = {
      subject: 'sales',
      from: 'float_button',
      phone: 'default',
      text,
    };

    goToSupportURL(whatsAppOptions);
  };

  const handleClick = e => {
    segmentTrack(eventState, { type: 'bold.co', utm: loopParamsSegment(), page_name: getPathName(), ...obj });

    if (event === 'contactsales' && wpText) {
      handleSalesSupport(e, wpText);
    }
  };

  const wrapperProps = {};
  if (isLink && isBlank) {
    wrapperProps.href = getSearchParams(url);
    wrapperProps.target = '_blank';
    wrapperProps.rel = 'noopener noreferrer';
  } else if (isLink) {
    wrapperProps.to = getSearchParams(url);
  } else if (isViral) {
    if (typeof navigator !== 'undefined' && isMobile()) {
      wrapperProps.onTouchEnd = handleClick;
    }
    wrapperProps.onMouseUp = handleClick;
  }

  const getPathName = () => {
    return typeof window !== 'undefined' ? window.location.pathname : '';
  };

  return (
    <WrapperComponent
      {...wrapperProps}
      onClick={!hideClick ? handleClick : () => {}}
      style={{ display: 'block', cursor: 'pointer' }}
      role={isLink ? 'button' : 'presentation'}
    >
      {children}
    </WrapperComponent>
  );
};

export default SegmentButton;
