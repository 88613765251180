import React, { useEffect, useState } from 'react';
import useCategoryList from 'hooks/useCategoryList';
import { Link } from 'gatsby';

import './styles.scss';

const CategoryNavBar = ({ location }) => {
  const categoryList = useCategoryList();
  const [classes, setClasses] = useState('category-nav');

  useEffect(() => {
    const isBlogpost = () => location.pathname.split('/').filter(v => v && !!v).length >= 2;

    if (typeof window !== 'undefined') {
      const computedClassNames = {
        'category-nav': true,
        'category-nav--gradient': isBlogpost(),
      };

      setClasses(
        Object.keys(computedClassNames)
          .filter(key => computedClassNames[key] && !!computedClassNames[key])
          .join(' '),
      );
    }
  }, [location]);

  return (
    <nav className={classes}>
      <div className="content-wrapper">
        <span className="category-nav__title">
          <Link to="/academia">Academia</Link>
        </span>
        <ul className="category-nav__menu">
          <li className="category-nav__item">
            <Link to="/academia" activeClassName="active">
              Todo
            </Link>
          </li>
          {categoryList.map(category => (
            <li className="category-nav__item" key={category.id}>
              <Link to={`/academia/${category.slug}`} activeClassName="active" partiallyActive>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default CategoryNavBar;
