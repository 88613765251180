import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import CookiesPopUp from 'components/CookiesPopUp';
import Seo from 'components/Seo';
import CategoryNavBar from 'components/CategoryNavBar';
import SiteNavigation from 'components/SiteNavigation';
import UserAlert from 'components/UserAlert';
import Footer from 'components/Footer';

const FabButton = loadable(() => import('components/FabButton'));

const AcademyLayout = ({ location, children, globalBoost, handleGlobalBoost }) => {
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const handleInteraction = () => {
      if (!location.search.includes('CI=true')) {
        setUserInteracted(true);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('click', handleInteraction);
      window.addEventListener('scroll', handleInteraction);
    }

    return () => {
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('scroll', handleInteraction);
    };
  }, [location.search]);

  return (
    <>
      <header>
        <SiteNavigation pageName={location.pathname} />
      </header>
      <main>
        <CategoryNavBar location={location} />
        {React.cloneElement(children, { globalBoost })}
      </main>
      <FabButton {...{ globalBoost, handleGlobalBoost, location }} />
      {userInteracted && <CookiesPopUp />}
      <Footer />
      <UserAlert location={location} />
    </>
  );
};

export default AcademyLayout;

export const Head = () => <Seo />;
