const SEGMENT_TRACK_PATHS = Object.freeze({
  boldplus_page: 'boldplus',
  boldsmart_page: 'boldsmart',
  comparison_page: 'comparar',
  contact_page: 'contacto',
  mpos_page: 'boldneo',
  payment_link_page: 'linkdepago',
  about_us_page: 'nosotros',
  referrals_page: 'referidos',
  sitemap: 'sitemap',
  tariff_page: 'tarifas',
  whatsapp_page: 'whatsapp',
  boldplus_manual_page: 'boldplus/manual',
  credit_page: [
    'legal/aliados-bold',
    'legal/contrato-apertura-credito',
    'legal/contrato-credito-capital',
    'legal/contrato-de-credito',
  ],
  terms_page: 'legal',
});

export default SEGMENT_TRACK_PATHS;
