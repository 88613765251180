import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';
import { BoldButton } from '@bold/web-components-react';
import SegmentButton from 'components/website/SegmentButton';
import MenuItems from 'components/SiteNavigation/MenuItems';
import BoldIconNew from 'assets/bold-icon-new.inline.svg';
import TimesIcon from 'assets/times-icon.inline.svg';
import CartIcon from 'assets/cart-icon.inline.svg';
import useMediaQuery from 'hooks/useMediaQuery';
import BREAKPOINTS from 'constants/breakpoints';
import COMPANY_INFO from 'constants/company-info';
import staticMenuItems from './menu-items';

import './styles.scss';

const SiteNavigation = ({ newHome }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menu = useRef();
  const matches = useMediaQuery(BREAKPOINTS.XL);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' && !matches) {
        setShowMenu(false);
        document.body.style.overflow = 'unset';
        menu.current.classList.remove('open');
        setTimeout(() => {
          menu.current.style.display = 'none';
        }, 300);
      }
    });
  }, [matches]);

  useEffect(() => {
    if (matches && menu.current) {
      menu.current.style.removeProperty('display');
      document.body.style.removeProperty('overflow');
      setShowMenu(true);
    } else {
      setShowMenu(false);
      menu.current.style.display = 'none';
    }
  }, [matches]);

  const handleToggleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
      document.body.style.overflow = 'unset';
      menu.current.classList.remove('open');
      setTimeout(() => {
        menu.current.style.display = 'none';
      }, 300);
    } else {
      setShowMenu(true);
      document.body.style.overflow = 'hidden';
      menu.current.style.display = 'block';
      setTimeout(() => {
        menu.current.classList.add('open');
      }, 100);
    }
  };

  return (
    <>
      <div className="site-navigation">
        <Link to="/" aria-label="Pagina principal Bold.co">
          <BoldIconNew className="icon-gradient" />
        </Link>
        <div className={`site-navigation__main-drawer ${newHome ? 'new-home' : ''}`} ref={menu}>
          <nav role="navigation" aria-labelledby="main-navigation-label">
            <h4 id="main-navigation-label" className="sr-only">
              Menu principal
            </h4>
            <ol>
              {staticMenuItems.map((item, id) => (
                <MenuItems item={item} key={`main-navigation-item${Number(id)}`} />
              ))}
            </ol>
          </nav>
          <div className="site-navigation__toolbar">
            <SegmentButton
              event="login"
              isBlank
              isLink
              url="https://comercios.bold.co/login"
              obj={{
                page_section: 'navigation_bar',
              }}
            >
              <span>Iniciar sesión</span>
            </SegmentButton>

            <SegmentButton
              event="register"
              isBlank
              isLink
              url="https://comercios.bold.co/onboarding/create-account"
              obj={{
                page_section: 'navigation_bar',
              }}
            >
              <BoldButton class="hydrated" type="button" color="accent" size="default">
                Registrarme
              </BoldButton>
            </SegmentButton>
          </div>
        </div>
        <ol>
          <li>
            <SegmentButton
              event="login"
              isBlank
              isLink
              url="https://comercios.bold.co/login"
              obj={{
                page_section: 'navigation_bar',
              }}
            >
              <span>Iniciar sesión</span>
            </SegmentButton>
            <SegmentButton
              event="register"
              isBlank
              isLink
              url="https://comercios.bold.co/onboarding/create-account"
              obj={{
                page_section: 'navigation_bar',
              }}
            >
              <BoldButton type="button" class="hydrated" color="accent" size="default">
                Registrarme
              </BoldButton>
            </SegmentButton>
          </li>
          <li>
            <SegmentButton
              event="purchase"
              isBlank
              isLink
              url={COMPANY_INFO.STORE}
              obj={{
                page_section: 'navigation_bar',
              }}
            >
              <span type="button" className="menu-cart-toggle" aria-label="Carrito de compra" aria-haspopup="menu">
                <CartIcon /> Comprar
              </span>
            </SegmentButton>
          </li>
          <li>
            <button
              type="button"
              className="menu-toggle"
              aria-haspopup="menu"
              aria-expanded={showMenu}
              onClick={handleToggleMenu}
            >
              <div>
                <span>Menú</span>
                <span>
                  <TimesIcon />
                </span>
              </div>
            </button>
          </li>
        </ol>
      </div>
    </>
  );
};

export default SiteNavigation;
