require('./src/styles/main.scss');
const React = require('react');
const { defineCustomElements } = require('@bold/web-components/loader');
const Layout = require('./src/components/Layout').default;
const { initSegment, segmentPage } = require('./src/vendors/segment');
const SEGMENT_TRACK_PATHS = require('./src/constants/segment-track-paths');

const getMatch = (s, o) => Object.keys(o).find(k => (Array.isArray(o[k]) ? o[k].find(v => v === s) : s.includes(o[k])));

defineCustomElements();

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onClientEntry = () => {
  initSegment();
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const hashedSection = document.getElementById(location.hash.slice(1));

  if (hashedSection) {
    const headerOffset = 96;
    const elementPosition = hashedSection.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  const isDifferentPage = location.pathname !== prevLocation?.pathname || null;

  if (isDifferentPage && window.analytics) {
    segmentPage(location.pathname === '/' ? 'home' : getMatch(location.pathname, SEGMENT_TRACK_PATHS), {
      path: location.pathname,
      url: location.href,
    });
  }
};
